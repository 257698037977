/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 72번째 로그에서는 꼬젯 님을 모시고 데이터 분석, CCPA, 팩트풀니스(한스 로슬링) 대한 이야기를 나눴습니다."), "\n", React.createElement(_components.h2, null, "구글 클라우드 플랫폼 서울 리전 오픈"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.zdnet.co.kr/view/?no=20200219105818"
  }, "구글 클라우드 플랫폼, 한국 공략 시동 - ZDNet korea")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/bigquery"
  }, "BigQuery: Cloud Data Warehouse  |  Google Cloud")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/python-datatime-cheetsheet"
  }, "파이썬(Python) datetime 클래스 치트시트 | 44BITS 기술 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Coordinated_Universal_Time"
  }, "Coordinated Universal Time - Wikipedia")), "\n"), "\n", React.createElement(_components.h2, null, "ODK 데이터 분석가 구인"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://odkmedia.bamboohr.com/jobs/"
  }, "ODK Careers - BambooHR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cojette.github.io/hireworkmate/"
  }, "같이 일할 동료를 찾아요 – Cojette (꼬젯) – 잡덕 잉여 데이터 분석가의 이것저것")), "\n"), "\n", React.createElement(_components.h2, null, "CCPA(California Consumer Privacy Act)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://oag.ca.gov/privacy/ccpa"
  }, "California Consumer Privacy Act (CCPA) | State of California - Department of Justice - Office of the Attorney General")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cojette.github.io/CCPA_Google/"
  }, "CCPA에 대응하는 구글 데이터 서비스 사용자의 자세 – Cojette (꼬젯) – 잡덕 잉여 데이터 분석가의 이것저것")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gdpr-info.eu/"
  }, "General Data Protection Regulation (GDPR) – Official Legal Text")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.korea.kr/special/policyCurationView.do?newsId=148867915"
  }, "정책위키 한눈에 보는 정책 - 데이터 3법")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bleepingcomputer.com/news/security/twitter-admits-recording-plaintext-passwords-in-internal-logs-just-like-github/"
  }, "Twitter Admits Recording Plaintext Passwords in Internal Logs, Just Like GitHub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://isms.kisa.or.kr/"
  }, "KISA 정보보호 및 개인정보보호관리체계 인증 - 한국인터넷진흥원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.appsflyer.com/"
  }, "AppsFlyer | Attribution Data You Can Trust")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://privacy.google.com/businesses/compliance/"
  }, "Compliance | How Google complies with data protection laws")), "\n"), "\n", React.createElement(_components.h2, null, "사내 SQL 교육"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dbeaver.io/"
  }, "DBeaver Community | Free Universal Database Tool")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/datagrip/"
  }, "DataGrip: The Cross-Platform IDE for Databases & SQL by JetBrains")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tableplus.com/"
  }, "TablePlus | Modern, Native Tool for Database Management")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.slideshare.net/zzsza/bigquery-147073606"
  }, "BigQuery의 모든 것(기획자, 마케터, 신입 데이터 분석가를 위한) 입문편")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/story.php?story_fbid=3164241010271111&id=100000557305988"
  }, "김창준 - https://youtu.be/ThGbP9wgkz8?t=20 \"누구나 프로그래밍을 할 수 있다\"는...")), "\n"), "\n", React.createElement(_components.h2, null, "팩트풀니스(한스 로슬링)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/"
  }, "Bear - Notes for iPhone, iPad and Mac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=184564546"
  }, "알라딘: 팩트풀니스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.youtube.com/playlist?list=PLhgI4N0j-gkh7AUjRG4x653MDad-U-xk6"
  }, "인구 변화로 본 한국의 현재와 미래 - 한스 로슬링 강연 - YouTube")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%ED%96%89%EB%8F%99%EA%B2%BD%EC%A0%9C%ED%95%99"
  }, "행동경제학 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/3361501"
  }, "넛지 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/59616093"
  }, "생각에 관한 생각 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hani.co.kr/arti/society/society_general/690038.html"
  }, "‘아동학대 사망 사건’ 터지면 떠오르는 ‘계모’…실제론 7.5%뿐 : 사회일반 : 사회 : 뉴스 : 한겨레")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.ted.com/speakers/hans_rosling"
  }, "Hans Rosling | Speaker | TED")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gapminder.org/"
  }, "Gapminder: Gapminder Foundation is fighting devastating ignorance with a fact-based worldview that everyone can understand.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gapminder.org/dollar-street/matrix?activeHouse=11"
  }, "Dollar Street")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
